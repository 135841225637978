import axios from 'axios';

import {TIMEOUT} from './const';

const CSRF_TOKEN_COOKIE_NAME = 'csrftoken';
const USERNAME_COOKIE_NAME = 'username';

function getUsernameFromCookie() {
    return getCookie(USERNAME_COOKIE_NAME);
}

async function getUsernameFromServer() {
    const response = await axios({
        method: 'get',
        url: '/api/check-auth/',
        signal: AbortSignal.timeout(TIMEOUT),
    });

    return response.data['isAuthenticated'] ? response.data['username'] : null;
}

function redirectToLogin() {
    const currentPath = window.location.href.slice(window.location.origin.length);
    const currentPathEncoded = currentPath.replace('&', encodeURIComponent('&'));
    window.location.replace(`/admin/login/?next=${currentPathEncoded}`);
}

function injectCsrf(axiosConfigOptions) {
    const optionsCopy = Object.assign({}, axiosConfigOptions);
    const methodsRequiringCsrfToken = ['PUT', 'POST', 'DELETE', 'PATCH'];
    const method = (optionsCopy.method || 'GET').toUpperCase();

    if (methodsRequiringCsrfToken.includes(method)) {
        optionsCopy.headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': getCsrfToken(),
        };
    }

    return optionsCopy;
}

function getCsrfToken() {
    return getCookie(CSRF_TOKEN_COOKIE_NAME);
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
}

export {getUsernameFromCookie, getUsernameFromServer, redirectToLogin, injectCsrf};
