import {useEffect} from 'react';

import {useTitlePrefixContext} from '../context/TitleContext';

export default function NotFound() {
    const {setTitlePrefix} = useTitlePrefixContext();

    useEffect(() => {
        setTitlePrefix('Not found');

        return () => {
            setTitlePrefix('');
        };
    }, [setTitlePrefix]);

    return (
        <div className="d-flex w-100 justify-content-center align-items-center">
            <h1>Not found</h1>
        </div>
    );
}
