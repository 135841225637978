import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {useTitlePrefixContext} from '../../context/TitleContext';
import {VALID_ITEM_IDENTIFIER_PATTERN} from '../../utils/const';
import './ItemLookup.scss';

export default function ItemLookup() {
    const navigate = useNavigate();
    let [queryString] = useSearchParams();

    const {setTitlePrefix} = useTitlePrefixContext();

    const [searchQuery, setSearchQuery] = useState(queryString.get('query') || '');

    const searchQueryInputId = 'big-search-query-input';

    useEffect(() => {
        document.getElementById(searchQueryInputId).focus();

        setTitlePrefix('New return');

        return () => {
            setTitlePrefix('');
        };
    }, [setTitlePrefix]);

    useEffect(() => {
        const searchQueryInput = document.getElementById(searchQueryInputId);
        const handler = (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                if (searchQuery && !VALID_ITEM_IDENTIFIER_PATTERN.test(searchQuery)) {
                    toast.error(
                        'Search query must be a listing ID, an auction-item number or a VALPN',
                    );
                    searchQueryInput.select();
                } else if (searchQuery) {
                    navigate(`/returns/${searchQuery}`);
                }
            }
        };
        searchQueryInput.addEventListener('keydown', handler);

        return () => {
            searchQueryInput.removeEventListener('keydown', handler);
        };
    }, [searchQuery, navigate]);

    return (
        <div className="d-flex w-100 justify-content-center align-items-center">
            <input type="text"
                   id={searchQueryInputId}
                   className="form-control"
                   placeholder="Listing ID, Auction-Item or VALPN"
                   value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value.trim())}
                   onBlur={(event) => {
                       event.target.focus();
                   }}/>
        </div>
    );
}
