import axios, {CanceledError} from 'axios';
import {Popover} from 'bootstrap';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import Spinner from '../../components/Common/Spinner';
import {useTitlePrefixContext} from '../../context/TitleContext';
import {injectCsrf} from '../../utils/auth';
import {TIMEOUT} from '../../utils/const';
import {formatDate, formatError, truncate} from '../../utils/string';
import './CustomerDetails.scss';

export default function CustomerDetails() {
    const navigate = useNavigate();
    let {usernameParam} = useParams();

    const {setTitlePrefix} = useTitlePrefixContext();

    const [isReady, setIsReady] = useState(false);
    const [username, setUsername] = useState(usernameParam);
    const [customer, setCustomer] = useState({});
    const [customerNoteDirty, setCustomerNoteDirty] = useState('');
    const [customerNoteIsEditable, setCustomerNoteIsEditable] = useState(false);
    const [customerNoteBeingSaved, setCustomerNoteBeingSaved] = useState(false);
    const [customerNoteHidden, setCustomerNoteHidden] = useState(false);

    const usernameInputId = 'search-query-input';
    const customerNoteContainerId = 'customer-note-container';
    const customerNoteInputId = 'customer-note-input';
    const controlBtnClass = 'control-btn';

    function resetState() {
        setIsReady(false);
    }

    useEffect(() => {
        setTitlePrefix(`${usernameParam} — customer details`);

        const abortController = new AbortController();
        axios({
            method: 'get',
            url: `/api/customers/${usernameParam}/`,
            signal: abortController.signal,
        }).then((response) => {
            const customer = response.data;
            setCustomer(customer);
            setCustomerNoteDirty(customer['note']);
            setIsReady(true);
        }).catch((error) => {
            // noinspection JSUnresolvedVariable
            if (error instanceof CanceledError && error.config.signal.reason === 'Unmount') {
                console.debug('Fetch aborted due to an unmount');
            } else {
                console.error(error);
                toast.error(<>Customer lookup has failed:<br/>{formatError(error)}</>);
                navigate(`/customers?username=${encodeURIComponent(usernameParam)}`, {replace: true});
            }
        });
        setTimeout(() => abortController.abort('Timeout'), TIMEOUT);

        return () => {
            abortController.abort('Unmount');
            setTitlePrefix('');
        };
    }, [usernameParam, navigate, setTitlePrefix]);

    const onSearchBtnClick = useCallback(() => {
        function focusAndSelect() {
            const searchQueryInput = document.getElementById(usernameInputId);
            searchQueryInput.focus();
            searchQueryInput.select();
        }

        if (!username) {
            focusAndSelect();
        } else if (username !== usernameParam) {
            resetState();
            navigate(`/customers/${username}`);
        }
    }, [usernameParam, username, navigate]);

    function onCustomerNoteEditBtnClick() {
        if (!customerNoteIsEditable && !customerNoteBeingSaved) {
            setCustomerNoteIsEditable(true);
            setTimeout(() => {
                const customerNoteInput = document.getElementById(customerNoteInputId);
                customerNoteInput.focus();
                customerNoteInput.setSelectionRange(-1, -1);
            }, 20);
        }
    }

    function onCustomerNoteDiscardBtnClick() {
        setCustomerNoteIsEditable(false);
        setCustomerNoteDirty(customer['note']);
    }

    const saveCustomerNote = useCallback(async () => {
        if (customerNoteBeingSaved) {
            return;
        }

        setCustomerNoteIsEditable(false);
        if (customerNoteDirty === customer['note']) {
            return;
        }

        setCustomerNoteBeingSaved(true);
        const originalCustomerNote = customer['note'];
        setCustomer((prev) => ({...prev, note: customerNoteDirty}));

        try {
            await toast.promise(
                axios(injectCsrf({
                    method: 'patch',
                    url: `/api/customers/${username}/`,
                    data: {
                        note: customerNoteDirty,
                    },
                    signal: AbortSignal.timeout(TIMEOUT),
                })),
                {
                    pending: 'Saving customer notes...',
                    success: 'Customer notes saved',
                    error: {
                        render({data}) {
                            return <>Failed to save customer notes:<br/>{formatError(data)}</>;
                        },
                    },
                },
            );
        } catch (error) {
            console.error(error);
            // Reverting the changes
            setCustomer((prev) => ({...prev, note: originalCustomerNote}));
            setCustomerNoteIsEditable(true);
        } finally {
            setCustomerNoteBeingSaved(false);
        }
    }, [customerNoteBeingSaved, customerNoteDirty]);

    const onClickOutsideOfCustomerNote = useCallback(async (event) => {
        const target = event.target;
        const input = document.getElementById(customerNoteInputId);
        const controlBtnsArr = Array.from(document.querySelectorAll(
            `#${customerNoteContainerId} .${controlBtnClass}`,
        ));

        if (customerNoteIsEditable && target !== input && !controlBtnsArr.includes(target)) {
            await saveCustomerNote();
        }
    }, [customerNoteIsEditable, saveCustomerNote]);

    function generateCreditBreakdown(creditId) {
        const breakdown = customer['creditBreakdown']['byCreditId'][creditId];
        if (!breakdown) {
            console.error(`Missing breakdown for credit #${creditId}`);
            return 'missing';
        }

        let result = '';

        for (let i = 0; i < breakdown.length; i++) {
            const entry = breakdown[i];
            const type = entry['type'];
            if (type === 'AUTOMATIC_INVOICE_ADJUSTMENT') {
                result += (
                    `<b>$${entry['amount']}</b>:
                    <a href='https://vistaauction.com/Account/Invoice/${entry['invoiceId']}' class='external-link'
                    target='_blank' rel='noreferrer'>Invoice #${entry['invoiceId']}</a>`
                );
            } else if (type === 'MANUAL_ENTER') {
                result += `<b>$${entry['amount']}</b>: ${entry['comment']}`;
            } else if (type === 'UNSPENT') {
                result += `<b>$${entry['amount']}</b>: Unspent`;
            } else {
                console.error(`Unknown credit breakdown entry type: ${type}`);
            }

            if (i < breakdown.length - 1) {
                result += '<br/>';
            }
        }

        return result;
    }

    // Initializes Bootstrap popovers
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map((popoverTriggerEl) => new Popover(popoverTriggerEl, {trigger: 'focus'}));
    }, [isReady, customer]);

    // Detects Enter key presses on the search query input
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const searchQueryInput = document.getElementById(usernameInputId);
        const handler = (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                searchQueryInput.blur();
                onSearchBtnClick();
            }
        };
        searchQueryInput.addEventListener('keydown', handler);

        return () => {
            searchQueryInput.removeEventListener('keydown', handler);
        };
    }, [isReady, onSearchBtnClick]);

    // Detects clicks outside of customer note input textarea
    useEffect(() => {
        if (!isReady) {
            return;
        }

        document.body.addEventListener('click', onClickOutsideOfCustomerNote);

        return () => {
            document.body.removeEventListener('click', onClickOutsideOfCustomerNote);
        };
    }, [isReady, onClickOutsideOfCustomerNote]);

    if (!isReady) {
        return <Spinner/>;
    }

    return (
        <div id="customer-details-container">
            <div id="customer-details" className="p-4">
                <div id="search-group" className="mb-4">
                    <input type="text"
                           id={usernameInputId}
                           className="form-control"
                           placeholder="Listing ID, Auction-Item or VALPN"
                           value={username}
                           onChange={(e) => setUsername(e.target.value.trim())}
                           tabIndex="1"/>
                    <i className="bi bi-search search-icon" onClick={onSearchBtnClick}></i>
                </div>
                <div id="customer-username-container" className="mb-3">
                    <h4 className="mb-1" title={customer['username']}>
                        <strong>
                            {truncate(customer['username'], 35)}
                        </strong>
                    </h4>
                    <a href={`/admin/store_credit/customer/${customer['id']}/change/`}
                       target="_blank"
                       rel="noreferrer">
                        View in admin
                    </a>
                </div>
                <div id="customer-stats-container" className="mb-3">
                    <ul className="mb-0">
                        <li>
                            <strong>Remaining credit:</strong>
                             ${customer['creditRemaining']}
                        </li>
                        <li>
                            <strong>Outstanding refunds:</strong>
                             ${customer['refundsOutstanding']}
                        </li>
                        <li>
                            <strong>Returns:</strong>
                             {customer['disputesTotal']} out
                            of {customer['purchasesTotal']}
                             purchases ({customer['disputePercentage']}%)
                        </li>
                        <li>
                            <strong>Returns (90 days):</strong>
                             {customer['disputesInLast90Days']} out
                            of {customer['purchasesInLast90Days']}
                             purchases ({customer['disputePercentageInLast90Days']}%)
                        </li>
                    </ul>
                </div>
                <div id={customerNoteContainerId} className="mb-3">
                    <div className="control-group">
                        <span className="collapse-btn"
                              onClick={() => setCustomerNoteHidden((prev) => !prev)}>
                            [{customerNoteHidden ? '+' : '-'}]
                        </span>
                        <label htmlFor={customerNoteInputId} className="form-label fw-bold mb-0">
                            {customerNoteHidden ? '...' : 'Customer notes'}
                        </label>
                        <div className={customerNoteHidden ? 'd-none' : 'd-inline-block'}>
                            <span
                                className={`${controlBtnClass} edit-btn ${customerNoteIsEditable ? 'd-none' : ''} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Edit"
                                onClick={onCustomerNoteEditBtnClick}>
                                ✏️
                            </span>
                            <span
                                className={`${controlBtnClass} save-btn ${customerNoteIsEditable ? '' : 'd-none'} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Save"
                                onClick={saveCustomerNote}>
                                ✔️
                            </span>
                            <span
                                className={`${controlBtnClass} discard-btn ${customerNoteIsEditable ? '' : 'd-none'} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Discard"
                                onClick={onCustomerNoteDiscardBtnClick}>
                                ✖️
                            </span>
                        </div>
                    </div>
                    <textarea id={customerNoteInputId}
                              className={`form-control ${customerNoteHidden ? 'd-none' : ''}`}
                              placeholder="Customer has no notes"
                              maxLength="1000"
                              value={customerNoteIsEditable ? customerNoteDirty : customer['note']}
                              onClick={onCustomerNoteEditBtnClick}
                              onChange={(e) => setCustomerNoteDirty(e.target.value)}
                              readOnly={!customerNoteIsEditable}
                              disabled={customerNoteBeingSaved}>
                    </textarea>
                </div>
                <div id="customer-dispute-history-container">
                    <label className="form-label fw-bold mb-0">
                        Recent returns
                    </label>
                    <div id="customer-dispute-history-table-wrapper">
                        <table className={`table table-bordered ${customer['disputes'].length === 0 ? 'empty' : ''}`}>
                            <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Identifier</th>
                                <th scope="col">Product</th>
                                <th scope="col">Price</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Reason</th>
                                <th scope="col">Note</th>
                            </tr>
                            </thead>
                            <tbody>
                            {customer['disputes'].length > 0
                                ? customer['disputes'].slice(0, 20).map((dispute, i) => (
                                    <tr key={i}>
                                        <td>
                                            <a href={`/admin/store_credit/itemdispute/${dispute['id']}/change/`}
                                               title="View in admin"
                                               target="_blank"
                                               rel="noreferrer">
                                                {formatDate(dispute['disputedOn'])}
                                            </a>
                                            {dispute['isDraft'] && (
                                                <i className="bi bi-journal-text draft-icon" title="Draft"></i>
                                            )}
                                        </td>
                                        <td>
                                            <a href={`https://vistaauction.com/Listing/Details/${dispute['item']['listingId']}`}
                                               className="external-link"
                                               target="_blank"
                                               rel="noreferrer">
                                                {dispute['item']['auctionNumber'] > 0 ? dispute['item']['auctionNumber'] : 'unk.'}
                                                -
                                                {dispute['item']['itemNumber'] > 0 ? dispute['item']['itemNumber'] : 'unk.'}
                                            </a>
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['item']['title']}>
                                                {dispute['item']['title']}
                                            </span>
                                        </td>
                                        <td>
                                            ${dispute['item']['totalPrice']}
                                        </td>
                                        <td>
                                            {dispute['creditIssued'] && (
                                                <span className="credit-amount"
                                                      data-bs-toggle="popover"
                                                      data-bs-trigger="focus"
                                                      title="Credit breakdown"
                                                      data-bs-content={dispute['isDraft']
                                                          ? 'Credit will be issued after the draft status is removed'
                                                          : generateCreditBreakdown(dispute['creditIssued']['id'])}
                                                      data-bs-html="true"
                                                      tabIndex="0">
                                                    ${dispute['creditIssued']['amount']}
                                                </span>
                                            )}
                                            {dispute['refundIssued'] && !dispute['creditIssued'] && (
                                                <span className="credit-amount"
                                                      data-bs-toggle="popover"
                                                      data-bs-trigger="focus"
                                                      data-bs-content={dispute['isDraft']
                                                          ? 'Refund will be issued after the draft status is removed'
                                                          : 'This is a refund'}
                                                      tabIndex="0">
                                                    ${dispute['refundIssued']['amount']}
                                                </span>
                                            )}
                                            {!dispute['creditIssued'] && !dispute['refundIssued'] && (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['reason']}>
                                                {dispute['reason']}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['note']}>
                                                {dispute['note'] || '—'}
                                            </span>
                                        </td>
                                    </tr>
                                ))
                                : (
                                    <tr>
                                        <td colSpan="7" className="text-center fst-italic">
                                            The customer has no returns
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
