import React, {createContext, useContext, useState} from 'react';
import {getUsernameFromCookie} from '../utils/auth';

const usernameFromCookie = getUsernameFromCookie();
const AuthContext = createContext(null);

export const AuthContextProvider = ({children}) => {
    const [username, setUsername] = useState(usernameFromCookie);

    return (
        <AuthContext.Provider value={{username, setUsername}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    return useContext(AuthContext);
};
