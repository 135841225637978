import {Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import './App.scss';
import Layout from './components/Layout';
import {AuthContextProvider} from './context/AuthContext';
import {TitlePrefixContextProvider} from './context/TitleContext';
import DisputeCreation from './pages/Disputes/DisputeCreation';
import ItemLookup from './pages/Disputes/ItemLookup';
import CustomerLookup from './pages/Customers/CustomerLookup';
import CustomerDetails from './pages/Customers/CustomerDetails';
import NotFound from './pages/NotFound';

export default function App() {
    return (
        <>
            <AuthContextProvider>
                <TitlePrefixContextProvider>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<Navigate to="/returns/new" replace={true}/>}/>

                            <Route path="returns/new" element={<ItemLookup/>}/>
                            <Route path="returns/:searchQueryParam" element={<DisputeCreation/>}/>

                            <Route path="customers" element={<CustomerLookup/>}/>
                            <Route path="customers/:usernameParam" element={<CustomerDetails/>}/>

                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </TitlePrefixContextProvider>
            </AuthContextProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}
