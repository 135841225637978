import React, {createContext, useContext, useState} from 'react';

const TitlePrefixContext = createContext(null);

export const TitlePrefixContextProvider = ({children}) => {
    const [titlePrefix, setTitlePrefix] = useState('');

    return (
        <TitlePrefixContext.Provider value={{titlePrefix, setTitlePrefix}}>
            {children}
        </TitlePrefixContext.Provider>
    );
};

export const useTitlePrefixContext = () => {
    return useContext(TitlePrefixContext);
};
