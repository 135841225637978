import axios from 'axios';
import {useEffect} from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useAuthContext} from '../context/AuthContext';
import {useTitlePrefixContext} from '../context/TitleContext';
import {getUsernameFromServer, injectCsrf, redirectToLogin} from '../utils/auth';
import {SITE_NAME, TIMEOUT} from '../utils/const';
import {formatError} from '../utils/string';
import './Layout.scss';

export default function Layout() {
    const {username, setUsername} = useAuthContext();
    const {titlePrefix} = useTitlePrefixContext();

    useEffect(() => {
        getUsernameFromServer().then((actualUsername) => {
            setUsername(actualUsername);
        }).catch((error) => {
            console.error(error);
            toast.error(<>Authorization failure:<br/>{formatError(error)}<br/>Please contact the dev team</>);
        });
    }, []);

    useEffect(() => {
        if (!username) {
            redirectToLogin();
        }
    }, [username]);

    useEffect(() => {
        document.title = titlePrefix ? `${titlePrefix} | ${SITE_NAME}` : SITE_NAME;
    }, [titlePrefix]);

    async function logout(event) {
        event.preventDefault();

        if (!window.confirm('You are about to log out. Continue?')) {
            return;
        }

        try {
            await toast.promise(
                axios(injectCsrf({
                    method: 'post',
                    url: '/api/logout/',
                    signal: AbortSignal.timeout(TIMEOUT),
                })),
                {
                    pending: 'Logging out...',
                    success: 'Logged out. Redirecting...',
                    error: {
                        render({data}) {
                            return <>Failed to logout:<br/>{formatError(data)}</>;
                        },
                    },
                },
            );
            window.location.href = '/admin/login/?next=/returns/new';
        } catch (error) {
            console.error(error);
        }
    }

    if (!username) {
        return <>Redirecting to the login page...</>;
    }

    return (
        <>
            <nav id="nav-bar" className="navbar navbar-expand-lg navbar-dark">
                <div className="container-fluid">
                    <NavLink to="/" className="navbar-brand fw-semibold text-uppercase">Store credit</NavLink>
                    <div className="navbar-content">
                        <ul className="navbar-nav mb-0">
                            <li className="nav-item">
                                <NavLink to="/returns/new" className="nav-link" end>New return</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/customers" className="nav-link" end>Customer lookup</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/admin/store_credit/itemdispute/">Admin panel</a>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar-auth">
                        <span className="greeting fw-bold">Hello, {username}</span>
                        <a href="/logout" className="logout nav-link text-uppercase" onClick={logout}>logout</a>
                    </div>
                </div>
            </nav>
            <div id="content" className="container-fluid p-0">
                <Outlet/>
            </div>
        </>
    );
}
