import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {useTitlePrefixContext} from '../../context/TitleContext';
import './CustomerLookup.scss';

export default function CustomerLookup() {
    const navigate = useNavigate();
    let [queryString] = useSearchParams();

    const {setTitlePrefix} = useTitlePrefixContext();

    const [username, setUsername] = useState(queryString.get('username') || '');

    const searchQueryInputId = 'big-search-query-input';

    useEffect(() => {
        document.getElementById(searchQueryInputId).focus();

        setTitlePrefix('Customer lookup');

        return () => {
            setTitlePrefix('');
        };
    }, [setTitlePrefix]);

    useEffect(() => {
        const searchQueryInput = document.getElementById(searchQueryInputId);
        const handler = (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                if (username) {
                    navigate(`/customers/${username}`);
                }
            }
        };
        searchQueryInput.addEventListener('keydown', handler);

        return () => {
            searchQueryInput.removeEventListener('keydown', handler);
        };
    }, [username, navigate]);

    return (
        <div className="d-flex w-100 justify-content-center align-items-center">
            <input type="text"
                   id={searchQueryInputId}
                   className="form-control"
                   placeholder="Username"
                   value={username}
                   onChange={(e) => setUsername(e.target.value.trim())}
                   onBlur={(event) => {
                       event.target.focus();
                   }}/>
        </div>
    );
}
