import {AxiosError} from 'axios';
import dateFormat from 'dateformat';
import YAML from 'yaml'

function truncate(s, maxLength) {
    if (s.length <= maxLength) {
        return s;
    }

    const truncatedStr = (
        s
        .substring(0, Math.max(maxLength - 3, 0))
        // Stripping whitespace and punctuation from the right end
        .replace(/[ \t\n\r\v\f!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/, '')
    );
    return `${truncatedStr}...`;
}

function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function formatDate(dateStr, format = 'mmm d, yyyy', currentYearFormat = 'mmm d') {
    const locale = 'en-US';
    const timeZone = 'America/New_York';
    const localeStr = new Date(dateStr).toLocaleString(locale, {timeZone: timeZone});

    return currentYearFormat && localeStr.includes(getCurrentYear(timeZone))
        ? dateFormat(localeStr, currentYearFormat)
        : dateFormat(localeStr, format);
}

function getCurrentYear(timeZone) {
    const dateParts = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        timeZone: timeZone,
    }).formatToParts(new Date());

    // noinspection JSUnresolvedVariable
    return dateParts.find(part => part.type === 'year').value;
}

function formatError(error) {
    if (error instanceof AxiosError) {
        if (error.code === 'ERR_BAD_REQUEST') {
            if (error.response.status === 404) {
                return <>Not found</>;
            } else {
                const dataCopy = {...error.response.data};
                if (dataCopy.hasOwnProperty('errors')) {
                    // noinspection JSUnusedLocalSymbols
                    dataCopy.errors = dataCopy.errors.map((e) => Object.fromEntries(
                        Object.entries(e)
                        .filter(([k, v]) => v !== null)
                        // Sorting keys alphabetically presents the errors in a more readable form: attr, code, details
                        .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
                    ));
                }
                const formattedError = (
                    YAML
                    .stringify(dataCopy)
                    .replace(/^ +/gm, (match) => ' '.repeat(match.length))
                    .replace(/\r?\n$/, '')
                    .replace(/\r?\n/gm, '<br/>')
                );

                return (
                    <span style={{fontFamily: 'Courier New, monospace'}}
                          dangerouslySetInnerHTML={{__html: formattedError}}>
                    </span>
                );
            }
        } else if (error.code === 'ERR_CANCELED') {
            // noinspection JSUnresolvedVariable
            const reason = error.config.signal.reason;
            if (reason instanceof DOMException) {
                return reason.name === 'TimeoutError'
                    ? <>Timeout</>
                    : <>{reason.name}: {capitalize(reason.message)}</>;
            } else {
                return <>{capitalize(reason)}</>;
            }
        } else {
            return <>{error.message}</>;
        }
    } else {
        return <>{error.toString()}</>;
    }
}

export {truncate, capitalize, formatDate, formatError};
